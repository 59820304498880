import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from "@angular/core";
import { StoreItemService } from "../../Services/store-item.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnInit, OnChanges {
  @Output() applyFilters = new EventEmitter();
  @Input() listType: string;
  @Input() calendarAvailable: any;
   public savedFilter: any[] = [];
   public filterForm: FormGroup;
   public filterConfiguration: any[];
   public months = [0,1,2,3,4,5,6,7,8,9,10,11,12];

  constructor(private storeItemServiceFilter: StoreItemService, private formBuilder: FormBuilder) {
    this.filterConfiguration = [
      { value: 0, label:'Tutti' }, 
      { value: 2020, label:'2020' },
      { value: 2021, label:'2021' },
      { value: 2022, label:'2022' },
      { value: 2023, label:'2023' },
      { value: 2024, label:'2024' },
      { value: 2025, label:'2025' }
    ];
   }

  ngOnInit() {
    this.filterForm && this.filterForm.reset();
    this.setForm();
  }

  ngOnChanges() {
    console.log(this.calendarAvailable, this.listType);
    this.savedFilter = [];
    if (this.listType == 'scadute' && this.calendarAvailable && Object.keys(this.calendarAvailable).length) {
      this.filterConfiguration = Object.keys(this.calendarAvailable).map((yearKey: any) => ({ value: +yearKey, label: yearKey+'' }));
      this.months = this.calendarAvailable[Object.keys(this.calendarAvailable)[0]].map((m => +m));

      this.filterForm.controls["filterAnno"].setValue(+Object.keys(this.calendarAvailable)[0], { onlySelf: true });
      this.filterForm.controls["filterMese"].setValue(this.months[0], { onlySelf: true });
    } else {
      this.filterForm && this.filterForm.reset();
      this.setForm();
      this.filterConfiguration = [
        // { value: 0, label:'Tutti' }, 
        { value: 2020, label:'2020' },
        { value: 2021, label:'2021' },
        { value: 2022, label:'2022' },
        { value: 2023, label:'2023' },
        { value: 2024, label:'2024' },
        { value: 2025, label:'2025' }
      ];
      this.months = [0,1,2,3,4,5,6,7,8,9,10,11,12];
    }
  }

  getMonthName(monthIdx: number) {
    let label = 'Tutti';
    switch(monthIdx) {
      case 0: label = 'Tutti'; break;
      case 1: label = 'Gennaio'; break;
      case 2: label = 'Febbraio'; break;
      case 3: label = 'Marzo'; break;
      case 4: label = 'Aprile'; break;
      case 5: label = 'Maggio'; break;
      case 6: label = 'Giugno'; break;
      case 7: label = 'Luglio'; break;
      case 8: label = 'Agosto'; break;
      case 9: label = 'Settembre'; break;
      case 10: label = 'Ottobre'; break;
      case 11: label = 'Novembre'; break;
      case 12: label = 'Dicembre'; break;
      default: label = 'Tutti'; break;
    }
    return label;
  }

   setForm() {
      this.filterForm = this.formBuilder.group({
        filterAnno: [this.checkFilter('anno'), Validators.compose([])],
        filterMese: [this.checkFilter('mese'), Validators.compose([])],
        filterTipologia: [this.checkFilter('tipologiaPagamento'),Validators.compose([])]
      })
   }

   setFilter(type: string, event) {
    console.log(type, event.target.value, this.listType);
    

     if (type == "anno") {
      if (this.listType == 'scadute') {
        this.months = this.calendarAvailable[event.target.value].map((m => +m));
        this.savedFilter["mese"] = this.months[0];
        console.log('ANNO', event.target.value, this.calendarAvailable[event.target.value], this.months, this.months[0]);
       this.filterForm.controls["filterMese"].setValue(this.months[0], { onlySelf: true });
      };
       this.savedFilter["anno"] = event.target.value;
       this.filterForm.controls["filterAnno"].setValue(event.target.value, { onlySelf: true });
     }
     else { if (this.listType != 'scadute') this.savedFilter["anno"] = this.checkFilter('anno') }

     if (type == "mese") {
       this.savedFilter["mese"] = event.target.value;
       this.filterForm.controls["filterMese"].setValue(event.target.value, { onlySelf: true });
     }
     else { if (this.listType != 'scadute') this.savedFilter["mese"] = this.checkFilter('mese') }

     if (type == "tipologiaPagamento") {
       this.savedFilter["tipologiaPagamento"] = event.target.value;
       this.filterForm.controls["filterTipologia"].setValue(event.target.value, { onlySelf: true });
     }
     else { this.savedFilter["tipologiaPagamento"] = this.checkFilter('tipologiaPagamento') }

     let filters = {
       anno: this.savedFilter["anno"] || this.filterForm.get('filterAnno').value,
       mese: this.savedFilter["mese"] || this.filterForm.get('filterMese').value,
       tipologiaPagamento: this.savedFilter["tipologiaPagamento"]
     }

     console.log(filters);

     this.storeItemServiceFilter.write("savedFilter", filters);
     this.applyFilters.emit(filters);
   }

  resetFilter() {
    let filters = [];
    this.storeItemServiceFilter.write("savedFilter", filters);
  }


  openNav() {
    document.getElementById("sidebar-filter").style.width = "180px";
  }

  closeNav() {
    document.getElementById("sidebar-filter").style.width = "0";
  }

  checkFilter(type){

    if (this.storeItemServiceFilter.read('savedFilter')) {
      let filter = this.storeItemServiceFilter.read('savedFilter');

      let anno = filter['anno'];
      let mese = filter['mese'];
      let tipologia = filter['tipologiaPagamento'];

      // console.log(type, filter);

      if (type == "anno") {
        if (anno) return anno;
        else return (new Date()).getFullYear();
      }
      if (type == "mese") {
        if (mese) return mese;
        else return "0";
      }
      if (type == "tipologiaPagamento") {
        if (tipologia) return tipologia;
        else return "-1";
      }
    }
    else {
      if (type == "anno") return (new Date()).getFullYear();
      if (type == "mese") return "0";
      if (type == "tipologiaPagamento") return "-1";
    }
    

  }

}
