<div id="top-button" class="d-flex">
  <button class="btn btn-link mr-2 ml-2" type="button" [ngClass]="{'selected': type == 'confermati'}" (click)="getFatture('confermati')">Confermate</button>
  <button class="btn btn-link mr-2 ml-2" type="button" [ngClass]="{'selected': type == 'dapagare'}" (click)="getFatture('dapagare')">Da pagare</button>
  <button class="btn btn-link mr-2 ml-2" type="button" [ngClass]="{'selected': type == 'pagati'}" (click)="getFatture('pagati')">Pagate</button>
  <button class="btn btn-link ml-2" type="button" [ngClass]="{'selected': type == 'scadute'}" (click)="getFatture('scadute')">Scadute</button>
</div>
<div class="row justify-content-center" id="main-content">
  <div class="col-lg-2" style="padding: 0;">
    <app-filter [calendarAvailable]="calendarAvailable | async" [listType]="type" class="left" (applyFilters)="setFilter($event)"></app-filter>
  </div>
  <div class="col-lg-10" style="padding: 0;">
    <div *ngIf="!pagate" class="row customTable justify-content-center" style="align-items: baseline; " class="table-container">
      <div class="col-12 ">
        <div class="col-11 pt-3 pb-3 title-container d-flex">
          <h5 *ngIf="type == 'dapagare'" class="gray" style="width: 100%;">Da pagare</h5>
          <h5 *ngIf="type == 'confermati'" class="gray"  style="width: 100%;">Confermate</h5>
          <h5 *ngIf="type == 'pagati'" class="gray"  style="width: 100%;">Pagate</h5>
          <h5 *ngIf="type == 'scadute'" class="gray"  style="width: 100%;">Scadute</h5>
          <h5 class="gray text-right"  style="width: inherit;">Totale: {{items.length}}</h5>
        </div>
        <div class="container-list">
          <div class="row data-container p-1">
            <div *ngIf="['scadute', 'pagati'].includes(type) && items.length" style="display: flex; flex-direction: row; gap: 10px; align-items: center; padding: 1rem; padding-bottom: 0;">
              <div 
              *ngIf="type == 'scadute'"
              style="
                background-color: #fff;
                border-radius: 10px;
                padding: 10px 15px;
                border: 1px solid #dee2e6;
              ">
                <h5 class="gray">Utenti</h5>
                <h5 style="margin-bottom: 0; color: #3a414a; font-weight: normal;">{{ items.length }}</h5>
              </div>
              <div
              style="
                background-color: #fff;
                border-radius: 10px;
                padding: 10px 15px;
                border: 1px solid #dee2e6;
              ">
                <h5 class="gray">Importi</h5>
                <h5 style="margin-bottom: 0; color: #3a414a; font-weight: normal;">{{ getTotalAmount(items, type) }}€</h5>
              </div>
            </div>
            <app-table class="col-12 pt-3 pb-3" [loader]="loader" [structure]="tableStructure" [items]="items" [typeLista]="type" (sortEvent)="sortBy($event)"></app-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>